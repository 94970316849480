import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React, { useState } from 'react';
import Tabs from "./components/Tabs";
import BookmarkContainer from "./components/BookmarkContainer";
import Home from "./pages/Home";
import Experiences from "./pages/Experiences";
import Projects from "./pages/Projects";
import Other from "./pages/Other";
import home from './icons/home.png';
import experiences from './icons/experiences.png';
import projects from './icons/projects.png';
import bitmoji from './icons/bitmoji.png';
import './css/App.css';

const tabArray = [
  {
      id: "home",
      name: "Home",
      icon: home,
      isActive: true
  },
  {
      id: "experiences",
      name: "Experiences",
      icon: experiences,
      isActive: false
  },
  {
      id: "projects",
      name: "Projects",
      icon: projects,
      isActive: false
  },
  {
      id: "other",
      name: "Other",
      icon: bitmoji,
      isActive: false
  }
]

function App() {
  const currentTab = (window.location.pathname === "/") ? "home" : window.location.pathname.slice(1);

  const [activeTab, setTab] = useState(currentTab);

  //When application first starts, or after page refresh
  tabArray.forEach(tab => {
    tab.isActive = (tab.id === currentTab);
  })

  console.log(tabArray);

  const switchTabs = (event, selectedTab) => {
      setTab(selectedTab);

      //Setting all the other tabs to inActive
      tabArray.forEach(tab => {
        tab.isActive = (tab.id === selectedTab);
      })
  }

  const whiteBackgroundStyle = {
    backgroundColor: "white"
  };

  const experiencesBackgroundStyle = {
    backgroundColor: "#b3ffe6"
  };

  const projectsBackgroundStyle = {
    backgroundColor: "#ccccff"
  };

  const changeStyle = (selectedTab) => {
    switch(selectedTab) {
      case "experiences":
        return experiencesBackgroundStyle;
      case "projects":
        return projectsBackgroundStyle;
      default:
        return whiteBackgroundStyle;
    }
  }

  return (
    <BrowserRouter>
      <div className="container">
        <div className="three-buttons">
          <span className="circle" style={{backgroundColor: "#66ff99"}}></span>
          <span className="circle" style={{backgroundColor: "#f5d189"}}></span>
          <span className="circle" style={{backgroundColor: "#f08e67"}}></span>
        </div>
        <Tabs tabArray={tabArray} switchTabs={switchTabs}/>
        <BookmarkContainer selectedTab={activeTab} />
        <div className="content" style={changeStyle(activeTab)}>
          <Routes>
            {/* <Redirect to="/home" /> */}
            <Route path="/" element={<Navigate replace to={`/${activeTab}`} />} />
            <Route path="/home" element={ <Home/> } />
            <Route path="/experiences" element={ <Experiences/> } />
            <Route path="/projects" element={ <Projects/> } />
            <Route path="/other" element={ <Other/> } />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
